@import '@/assets/scss/mixins';

.root {
    border-radius: 25px;
    border: 2px solid var(--core20);
    display: flex;
    padding: 12px 24px;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
    gap: 8px;
    min-width: max-content;
    user-select: none;
}

.symbol {
    color: var(--orangeDark);
}